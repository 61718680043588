import React, { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import FirebaseContext from './context';
import { Firebase, LoadedSessionData } from './firebase';
import { usePublicConfig } from '../hooks/usePublicConfig';
import { Notification } from '../components/Notification';
import { interpolateAppText, useDefaultAppTexts } from '../hooks/useAppTexts';
import { ShopNotFound } from '../components/ShopNotFound';
import { TReturnOptionsResponse } from '../../../../functions/src/shared';

const firebase = new Firebase();

export const FirebaseContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [publicConfig, isLoadingPublicConfig, configError] = usePublicConfig(firebase);
  const appTexts = useDefaultAppTexts();
  const [loadedSessionData, setLoadedSessionData] = useState<LoadedSessionData>();

  useEffect(() => {
    if (!publicConfig || loadedSessionData) {
      return;
    }

    const unsubscribe = firebase.auth.onAuthStateChanged(async (user) => {
      if (user === null) {
        return;
      }

      // if no data has been fetched yet, fetch the initial data
      try {
        setLoadedSessionData(await firebase.fetchInitialData(publicConfig.language));
      } catch (error) {
        // if there is an error fetching the initial data, sign out the user
        firebase.auth.signOut();
        console.error('Error on initial data fetch: ', error);
      }
    });

    return unsubscribe;
  }, [publicConfig, loadedSessionData]);

  const setReturnOptions = (returnOptions: TReturnOptionsResponse) => {
    console.log({ ...loadedSessionData!, returnOptions });
    setLoadedSessionData({ ...loadedSessionData!, returnOptions });
  };

  const getShopName = () => {
    return (firebase.shopName || loadedSessionData?.userData?.shop)?.split('.')[0];
  };

  const getPathTo = (destination: string) => {
    if (firebase.hasCustomDomain()) return `/${destination}`;

    return `/${getShopName()}/${destination}`;
  };

  const resetSession = useCallback(async () => {
    await firebase.auth.signOut();
    setLoadedSessionData(undefined);
  }, []);

  if (!publicConfig && !isLoadingPublicConfig) {
    return <ShopNotFound />;
  }

  const loadingMarkup = <div className="custom-loader is-loading is-center-page"></div>;

  const errorMarkup = (
    <div className="items-list">
      <Notification
        status="warning"
        message={interpolateAppText(appTexts.generic.error.other, {
          supportEmail: publicConfig?.supportEmail,
        })}
      />
    </div>
  );

  return (
    <FirebaseContext.Provider
      value={{
        ...firebase,
        getPathTo,
        getShopName,
        resetSession,
        publicConfig,
        loadedSessionData,
        setReturnOptions,
      }}
    >
      {configError ? errorMarkup : publicConfig ? children : loadingMarkup}
    </FirebaseContext.Provider>
  );
};
