import React, { useEffect, useMemo, useState } from 'react';
import {
  FullWizardItem,
  WizardAction,
  WizardContext,
} from '../../contexts/WizardContext/WizardContext';
import ContinueWithReturn from './ContinueWithReturn';
import ItemCard from './ItemCard';
import ItemWizard from '../ItemWizard/ItemWizard';
import { Notification } from '../Notification';
import { MultipleRefundsPossibleNotification } from '../MultipleRefundsPossibleNotification';
import moment from '../../helpers/localizedMoment';
import { localeMapping } from '../../contexts/AppTextsContext/localeMapping';
import { useNonNullableContext } from '../../hooks/useNonNullableContext';
import { useFirebase } from '../../firebase/context';
import { useEnrichedLineItemData, useOrderData } from '../../hooks/useOrderData';
import { interpolateAppText, useAppTexts } from '../../hooks/useAppTexts';
import { useHistory } from 'react-router-dom';
import {
  calculateIfWindowIsOpen,
  deriveOrderProperties,
  evaluateCondition,
  filterByCondition,
  TReturnOptionsResponse,
} from '../../../../../functions/src/shared';
import { useLoadedFirebaseData } from '../../firebase/hooks';
import './ItemsListUnsubmitted.css';
import { useWarrantyMailtoUrl } from './useWarrantyMailtoUrl';
import { PreviousRmasStatuses } from './PreviousRmasStatuses/PreviousRmasStatuses';
import { ReturnAlreadySubmittedNotification } from '../ReturnAlreadySubmittedNotification';

const ItemsListUnsubmitted = () => {
  const appTexts = useAppTexts();
  const firebase = useFirebase();
  const history = useHistory();
  const {
    publicConfig: appThemeData,
    returnOptions,
    discounts,
    config,
    order,
    rmaSummary,
  } = useLoadedFirebaseData();
  const { supportEmail, language } = appThemeData;

  // Set moment locale based on domain language
  moment.locale(localeMapping(language));

  const { order: orderData } = useOrderData();

  const {
    collectedWizardData,
    setWizardData,
    refundTotal,
    hasExchangeActions,
    isReturnAlreadySubmitted,
    keptItemsAdjustments,
  } = useNonNullableContext(WizardContext);

  const { lineItems, allItemsAlreadyReturned } = useEnrichedLineItemData();

  const maxAmountReturnsSubmitted =
    !config.allowMultiRma &&
    (rmaSummary?.rmaSubmitted || allItemsAlreadyReturned || isReturnAlreadySubmitted);

  const [selectedItem, setSelectedItem] = useState<FullWizardItem | null>(null);
  const [showWizard, setShowWizard] = useState(false);
  const [fetchingReturnOptions, setFetchingReturnOptions] = useState(false);

  const isExchangeOrder = discounts?.isExchangeOrder || false;

  const warrantyMailtoUrl = useWarrantyMailtoUrl(
    order,
    appThemeData.supportEmail,
    localeMapping(language),
  );

  // wizard methods
  // open
  const handleOpenWizard = (item: FullWizardItem) => {
    // only open if not already processed
    if (!collectedWizardData[item.selectionId]) {
      setSelectedItem(item);
      setShowWizard(true);
    }
  };

  // close
  const handleCloseWizard = () => {
    setSelectedItem(null);
    setShowWizard(false);
  };

  // add
  const handleAddWizardData = (initialItem: FullWizardItem, collectedData: WizardAction) => {
    const id = initialItem.selectionId;

    setWizardData({
      collectedWizardData: {
        ...collectedWizardData,
        [id]: {
          ...collectedData,
          initialItem,
        },
      },
    });
  };

  // remove
  const handleRemoveWizardData = (id: string) => {
    setWizardData({
      collectedWizardData: {
        ...collectedWizardData,
        [id]: null,
      },
    });
  };

  const refreshReturnOptions = async () => {
    setFetchingReturnOptions(true);
    const rmaItems: number[] = Object.keys(collectedWizardData)
      .map((key) => {
        return collectedWizardData[key]?.initialItem.lineItemId;
      })
      .filter((lineItem) => lineItem !== undefined) as number[];

    try {
      const response = (await firebase.refetchReturnOptions(
        appThemeData.language,
        rmaItems,
      )) as TReturnOptionsResponse;
      firebase.setReturnOptions(response);
    } finally {
      setFetchingReturnOptions(false);
    }
  };

  useEffect(() => {
    if (config.useWarehouseReturns) {
      refreshReturnOptions();
    }
  }, [collectedWizardData]);

  const orderBlockedMessage = useOrderBlockedMessage();

  const items = lineItems
    .filter(
      (d): d is FullWizardItem =>
        maxAmountReturnsSubmitted === false &&
        d.variant.requires_shipping === true &&
        d.warning === null &&
        !d.isStorefrontNullProduct &&
        !orderBlockedMessage,
    )
    .map((itemData, i) => {
      const adjustment = keptItemsAdjustments.find(
        (adjustment) => adjustment.lineItemId === itemData.lineItemId,
      );
      return (
        <ItemCard
          key={i}
          itemData={itemData}
          onClick={() => handleOpenWizard(itemData)}
          selected={collectedWizardData[itemData.selectionId]}
          deselect={handleRemoveWizardData}
          adjustments={adjustment ? [adjustment] : []}
        />
      );
    });

  const ineligibleItems = lineItems
    .filter(
      (d): d is FullWizardItem =>
        maxAmountReturnsSubmitted ||
        d.variant.requires_shipping === false ||
        d.variant.gift_card === true ||
        Boolean(d.warning !== null || d.isStorefrontNullProduct || orderBlockedMessage),
    )
    .map((itemData, i) => {
      // for items with no warning, we'll add the display price to the warning field (this will make the item unselectable and disabled)
      const warning = orderBlockedMessage || itemData.warning || itemData.displayOriginalPrice;

      const adjustment = keptItemsAdjustments.find(
        (adjustment) => adjustment.lineItemId === itemData.lineItemId,
      );

      return (
        <ItemCard
          key={i}
          itemData={itemData}
          warning={warning}
          adjustments={adjustment ? [adjustment] : []}
        />
      );
    });

  // products returned as null from storefront api
  const storefrontNullProducts = lineItems.filter((d) => d.isStorefrontNullProduct);

  const warrantyWindowStillOpen = Boolean(
    config.warrantyWindowInDays && calculateIfWindowIsOpen(order, config.warrantyWindowInDays),
  );

  const notificationsMarkup = (
    <React.Fragment>
      {(orderBlockedMessage || items.length === 0) && (
        <Notification status="warning">
          <div className="order-blocked-notification">
            <p>{orderBlockedMessage ?? appTexts.itemsList.noItemsEligible}</p>
            {!orderData.isReturnable && warrantyWindowStillOpen && (
              <>
                <p>{appTexts.itemsList.warrantyReturnPossible}</p>
                <a href={warrantyMailtoUrl}>{appTexts.itemsList.submitWarrantyRequest}</a>
              </>
            )}
          </div>
        </Notification>
      )}

      {refundTotal > 0 && isExchangeOrder && <MultipleRefundsPossibleNotification />}
      {storefrontNullProducts.length > 0 && orderData.isReturnable && (
        <Notification status="warning">
          <p>
            {interpolateAppText(appTexts.itemsList.productsWithTechnicalErrors, {
              supportEmail,
            })}
            :
          </p>
          <ul className="bullet-list-helper">
            {storefrontNullProducts.map((item, i) => {
              const { product, variant } = item;
              return (
                <li className="has-text-weight-semibold" key={`storefront-null-product-${i}`}>{`${
                  product.title
                }${variant.title ? ' - ' + variant.title : ''}`}</li>
              );
            })}
          </ul>
        </Notification>
      )}
    </React.Fragment>
  );

  return (
    <div className="items-list">
      <h1 className="items-list-title">{appTexts.itemsList.title}</h1>

      {orderData.returnableUntil && (
        <h2 className="items-list-subtitle">
          <span>
            {interpolateAppText(appTexts.itemsList.returnableUntil, {
              orderName: order.name,
              returnableUntilDate: orderData.returnableUntil,
            })}
          </span>
        </h2>
      )}

      {maxAmountReturnsSubmitted && <ReturnAlreadySubmittedNotification />}

      <PreviousRmasStatuses />

      {notificationsMarkup}

      {items.length > 0 && <div id="cy-eligible-items">{items}</div>}

      {ineligibleItems.length > 0 && orderData.isReturnableCountry && (
        <div id="cy-ineligible-items">
          {items.length > 0 && ( // only show title if we both have items that can be returned and items that cannot
            <h2 className="items-list-subtitle is-ineligible">
              {appTexts.itemsList.ineligibleSubtitle}
            </h2>
          )}
          {ineligibleItems}
        </div>
      )}

      <ItemWizard
        itemData={selectedItem}
        setWizardData={handleAddWizardData}
        showWizard={showWizard}
        closeWizard={handleCloseWizard}
      />

      <ContinueWithReturn
        onContinue={() => {
          if (!returnOptions) {
            throw new Error('order.returnOptions is undefined');
          }

          const applicableOptions = filterByCondition(
            returnOptions.options,
            hasExchangeActions ? 'exchange' : 'return',
          );

          if (!applicableOptions.length) {
            throw new Error('applicableOptions is empty');
          }

          if (applicableOptions.length === 1) {
            setWizardData({
              returnOption: applicableOptions[0],
            });
            history.push(firebase.getPathTo('review'));
          } else {
            history.push(firebase.getPathTo('shipping'));
          }
        }}
        continueDisabled={fetchingReturnOptions}
      />
    </div>
  );
};

const useOrderBlockedMessage = () => {
  const { order: orderData } = useOrderData();
  const { order, config } = useLoadedFirebaseData();
  const appTexts = useAppTexts();

  return useMemo(() => {
    if (!orderData.isReturnableCountry) {
      return appTexts.itemsList.noReturnCountry;
    }

    if (!orderData.isReturnable) {
      return appTexts.itemsList.returnWindowClosed;
    }

    const { orderBlocking } = config;

    if (!orderBlocking) {
      return null;
    }

    if (!order) {
      throw new Error('order is undefined');
    }

    const orderProps = deriveOrderProperties(order);

    const validBlock = orderBlocking.find((filter) => {
      return evaluateCondition(filter.condition, {
        order: orderProps,
      });
    });

    return validBlock?.userMessage ?? null;
  }, [config, order, appTexts, orderData.isReturnable, orderData.isReturnableCountry]);
};

export default ItemsListUnsubmitted;
