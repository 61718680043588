import React, { FC } from 'react';
import { useNonNullableContext } from '../hooks/useNonNullableContext';
import { Firebase, LoadedSessionData } from './firebase';
import { PublicConfig, TReturnOptionsResponse } from '../../../../functions/src/shared';

type FirebaseContextData = Firebase & {
  publicConfig: PublicConfig | undefined;
  loadedSessionData: LoadedSessionData | undefined;
  getShopName: () => string | undefined;
  getPathTo: (destination: string) => string;
  resetSession: () => Promise<void>;
  setReturnOptions: (returnOptions: TReturnOptionsResponse) => void;
};

export const FirebaseContext = React.createContext<FirebaseContextData | null>(null);

export const withFirebase =
  <P extends {}>(Component: FC<P & { firebase: FirebaseContextData | null }>) =>
  (props: P) =>
    (
      <FirebaseContext.Consumer>
        {(firebase) => <Component {...props} firebase={firebase} />}
      </FirebaseContext.Consumer>
    );

export const useFirebase = () => useNonNullableContext(FirebaseContext);

export default FirebaseContext;
